import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../../websocket/hooks/useWebsocket";

export interface InboundSync {
	created: string
	orders_created: number
	id: number
}

export interface OutboundSync {
	created: string
	total_units_synced: string
	processed_bundles: string
	status: 'pending' | 'success' | 'failed'
	id: number
}

export interface TokenField {
	label: string
	field: string
}

interface ScheduleInfo {
	next_run?: string 
}

export interface APIKeys {
	raw: string | null
	is_active: boolean
	prefix: string | null
	last_used_at: Date | null
}

interface Sync {
	page_state: any
	schedulable: Boolean
	inbound_sync_enabled: Boolean
	outbound_sync_enabled: Boolean
	api_keys_enabled: Boolean
	api_key: APIKeys,
	inbound_syncs: InboundSync[]
	outbound_syncs: OutboundSync[]
	token_form: TokenField[]
	is_scheduled: Boolean
	next_run: string | null
}

const useOrderSync = () => {
	const [data, setData] = useState<Sync>({
		page_state: {},
		schedulable: false,
		inbound_sync_enabled: true,
		outbound_sync_enabled: false,
		api_keys_enabled: false,
		api_key: {
			raw: null,
			is_active: false, 
			prefix: null, 
			last_used_at: null
		},
		inbound_syncs: [],
		outbound_syncs: [],
		token_form: [], 
		is_scheduled: false,
		next_run: null
	});

	const { loading, sendMessage, isVisible, setPageState, isReady } = useWebsocket('/main/sync/', setData);

	let pageState: any = data.page_state;
	const schedulable = data.schedulable 
	const inboundSyncEnabled = data.inbound_sync_enabled
	const inboundSyncs = data.inbound_syncs
	const outboundSyncEnabled = data.outbound_sync_enabled
	const outboundSyncs = data.outbound_syncs
	const apiKeysEnabled = data.api_keys_enabled
	const apiKey = data.api_key
	const tokenForm = data.token_form
	const isScheduled = data.is_scheduled 
	const nextRun = data.next_run 

	return {
		loading, sendMessage, isVisible, setPageState,
		pageState, isReady, schedulable, 
		apiKeysEnabled, apiKey,
		inboundSyncEnabled, inboundSyncs, 
		outboundSyncEnabled, outboundSyncs,
		tokenForm, 
		isScheduled, nextRun
	};
};

export default useOrderSync;
