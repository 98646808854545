import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Schedule as ScheduleIcon, EventBusy, Inventory } from '@mui/icons-material';
import { Box, TextField, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, LinearProgress, useTheme, Tabs, Tab, IconButton, Tooltip } from '@mui/material';
import useSync, { InboundSync, OutboundSync, TokenField } from './hooks/useSync';
import { CheckCircle, Error, PendingActions } from '@mui/icons-material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type FormValues = {
	[key: string]: string;
};

const SyncDialog = () => {
	const { 
		sendMessage, schedulable,
		inboundSyncEnabled, inboundSyncs,
		outboundSyncEnabled, outboundSyncs,
		apiKeysEnabled, apiKey,
		tokenForm, isScheduled, nextRun
	} = useSync();

	// State for opening and closing dialogs
	const [open, setOpen] = useState(false);
	const [tokenDialogOpen, setTokenDialogOpen] = useState(false);
	const [apiKeyDialogOpen, setApiKeyDialogOpen] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>({});
	const [progressValue, setProgressValue] = useState(0); // For 'determinate' variant
	const [progressVariant, setProgressVariant] = useState<'determinate' | 'indeterminate'>('determinate');
	const [progressColor, setProgressColor] = useState<'primary' | 'inherit'>('primary'); // Change this based on sync state
	const [syncing, setSyncing] = useState(false); // To track if sync is in progress
	const [firstSyncId, setFirstSyncId] = useState(inboundSyncs?.[0]?.id || null);
	const [activeTab, setActiveTab] = useState<'inbound' | 'outbound'>(
		inboundSyncEnabled ? 'inbound' : 'outbound'
	);

	const theme = useTheme();

	// Sync completion logic - switch to 'green' and reset after 3 seconds
	useEffect(() => {
		const currentSyncs = activeTab === 'inbound' ? inboundSyncs : outboundSyncs;
		if (currentSyncs?.[0]?.id !== firstSyncId && firstSyncId !== null) {
			setSyncing(false);
			setProgressColor('inherit');
			setProgressVariant('determinate');
			setProgressValue(100);

			setTimeout(() => {
				setProgressValue(0);
				setProgressColor('primary');
			}, 3000);
		}
	}, [inboundSyncs, outboundSyncs, activeTab]);

	const handleSyncIn = () => {
		// Start syncing, set to indeterminate
		setSyncing(true);
		setFirstSyncId(inboundSyncs.length > 0 ? inboundSyncs[0].id : 0);
		setProgressVariant('indeterminate'); // Switch to indeterminate for sync animation
		setProgressColor('primary'); // Default blue color
		sendMessage('sync_in');
	};

	const handleSyncOut = () => {
		setSyncing(true);
		setFirstSyncId(outboundSyncs.length > 0 ? outboundSyncs[0].id : 0);
		setProgressVariant('indeterminate');
		setProgressColor('primary');
		sendMessage('sync_out');
	};

	// Track the last known schedule state to detect changes
	const [lastScheduleState, setLastScheduleState] = useState(isScheduled);
	const [isTogglingSchedule, setIsTogglingSchedule] = useState(false);

	// Update loading state based on websocket response
	useEffect(() => {
		if (isTogglingSchedule && isScheduled !== lastScheduleState) {
			setIsTogglingSchedule(false);
			setLastScheduleState(isScheduled);
		}
	}, [isScheduled]);

	const handleToggleSchedule = () => {
		setIsTogglingSchedule(true);
		setLastScheduleState(isScheduled);
		sendMessage('toggle_schedule', { enabled: !isScheduled });
	};

	const SyncStatusIcon = ({ status }: { status: 'pending' | 'success' | 'failed' | 'bundled' }) => {
		switch (status) {
			case 'success':
				return <CheckCircle sx={{ color: 'success.main' }} />
			case 'bundled':
				return <Inventory sx={{ color: 'success.light' }} />
			case 'failed':
				return <Error sx={{ color: 'error.main' }} />
			case 'pending':
				return <PendingActions sx={{ color: 'warning.main' }} />
			default:
				return null
		}
	}

	const renderSyncTable = () => {
		const syncs = activeTab === 'inbound' ? inboundSyncs : outboundSyncs;

		const handleCopyError = (errorMessage: string) => {
			navigator.clipboard.writeText(errorMessage);
		};

		return (
			<TableContainer component={Paper} sx={{
				marginTop: 2,
				borderRadius: 5,
				maxHeight: "40vh", // Approximately 5 rows worth of height
				overflow: "auto"   // Enables scrolling
			}}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sx={{ textAlign: 'center' }}>
								{activeTab === 'inbound' ? 'Orders Created' : 'Units Synced'}
							</TableCell>
							<TableCell sx={{ textAlign: 'center' }}>Synced At</TableCell>
							{activeTab === 'outbound' && (
								<>
									<TableCell sx={{ textAlign: 'center' }}>Status</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>Bundles</TableCell>
								</>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{syncs && syncs.map((sync, index) => (
							<TableRow key={index}>
								<TableCell sx={{ textAlign: 'center' }}>
									{activeTab === 'inbound'
										? (sync as InboundSync).orders_created
										: (sync as OutboundSync).total_units_synced
									}
								</TableCell>
								<TableCell sx={{ textAlign: 'center' }}>
									{new Date(sync.created).toLocaleString()}
								</TableCell>
								{activeTab === 'outbound' && (
									<>
										<TableCell sx={{ textAlign: 'center' }}>
											<Tooltip title={(sync as OutboundSync).status}>
												<div>  
													<SyncStatusIcon status={(sync as OutboundSync).status} />
												</div>
											</Tooltip>
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{(sync as OutboundSync).processed_bundles && (
												<Button
													onClick={() => handleCopyError((sync as OutboundSync).processed_bundles)}
													variant="text"
													size="small"
												>
													Copy Bundles
												</Button>
											)}
										</TableCell>
									</>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	// Clears form values when the token dialog opens
	const handleTokenOpen = () => {
		const initialFormValues = (tokenForm || []).reduce((acc, field: TokenField) => {
			acc[field.field] = '';
			return acc;
		}, {} as FormValues);
		setFormValues(initialFormValues || {});
		setTokenDialogOpen(true);
	};

	const handleTokenClose = () => {
		setTokenDialogOpen(false);
	};

	const handleFormChange = (field: string, value: string) => {
		setFormValues((prevValues) => ({
			...prevValues,
			[field]: value,
		}));
	};

	const handleSaveTokens = () => {
		sendMessage('submit_tokens', { tokens: { ...formValues } });
		setTokenDialogOpen(false);
		setFormValues({});
	};

	const handleApiKeyClose = () => {
		setApiKeyDialogOpen(false)
		sendMessage('clear_raw_key')
	}

	return (
		<div>
			
			<Button
				sx={{ borderRadius: 3 }}
				variant="outlined"
				color="primary"
				onClick={() => {setOpen(true)}}
			>
				Sync
			</Button>

			<Dialog open={open} onClose={() => {setOpen(false)}} PaperProps={{ sx: { borderRadius: 5, textWrap: 'nowrap' } }} >
				<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					Sync
				</DialogTitle>
				<DialogContent 
					dividers
					sx={{
						p: 2,
						minWidth: 400,  // Set a minimum width
						'& .MuiDialogContent-root': {
							padding: 0
						}
					}}
				>
					{/* Schedule Info Bar */}
					{schedulable && (
						<Box
							sx={{
								mb: 2,
								p: 2,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								bgcolor: 'background.default',
								border: 1,
								borderColor: 'divider',
								borderRadius: 2,
							}}
						>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
								<Typography variant="body2" fontWeight="medium">
									Scheduled Sync
								</Typography>
								{isScheduled && nextRun && (
									<Typography variant="caption" color="text.secondary">
										Next run: {nextRun}
									</Typography>
								)}
							</Box>
							<Tooltip title={isScheduled ? 'Disable scheduled sync' : 'Enable scheduled sync'}>
								<IconButton
									size="small"
									onClick={handleToggleSchedule}
									color={isScheduled ? 'primary' : 'default'}
								>
									{isScheduled ? <ScheduleIcon /> : <EventBusy />}
								</IconButton>
							</Tooltip>
						</Box>
					)}
					{/* Tabs - Only show if both syncs are enabled */}
					{inboundSyncEnabled && outboundSyncEnabled && (
						<Tabs
							value={activeTab}
							onChange={(_, newValue) => setActiveTab(newValue)}
							sx={{
								mb: 2,
								'& .MuiTabs-flexContainer': {
									width: '100%'
								},
								'& .MuiTab-root': {
									width: '50%'  // Each tab takes up half the space
								}
							}}
						>
							<Tab label="Inbound Sync" value="inbound" />
							<Tab label="Outbound Sync" value="outbound" />
						</Tabs>
					)}

					{/* Progress bar */}
					<Box sx={{ width: '100%', marginY: 2 }}>
						<LinearProgress
							variant={progressVariant}
							value={progressValue}
							color={progressColor}
							sx={{
								borderRadius: 5,
								transition: 'all 1s ease',
								...(progressColor === 'inherit' && {
									'& .MuiLinearProgress-bar': {
										backgroundColor: theme.palette.success.light,
									},
								}),
							}}
						/>
					</Box>


					{/* Sync Table */}
					{renderSyncTable()}
				</DialogContent>
				<DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box>
						<Button sx={{ borderRadius: 3 }} onClick={() => {setOpen(false)}} color="error">
							Close
						</Button>
					</Box>
					<Box>

						{tokenForm.length > 0 && (
							<Button sx={{ borderRadius: 3 }} onClick={handleTokenOpen} color="secondary">
								Tokens
							</Button>
						)}

						{apiKeysEnabled  && (
							<Button sx={{ borderRadius: 3 }} onClick={() => {setApiKeyDialogOpen(true)}} color="secondary">
								API Keys
							</Button>
						)}

						{inboundSyncEnabled && (
							<Button
								onClick={handleSyncIn}
								color="primary"
								disabled={syncing}
								sx={{ borderRadius: 3 }}
							>
								Sync In
							</Button>
						)}

						{outboundSyncEnabled && (
							<Button
								sx={{ borderRadius: 3 }}
								onClick={handleSyncOut}
								disabled={syncing}
								color="primary"
							>
								Sync Out
							</Button>
						)}

						
					</Box>
				</DialogActions>
			</Dialog>


			{/* Token Dialog */}
			<Dialog open={tokenDialogOpen} onClose={handleTokenClose} PaperProps={{ sx: { borderRadius: 5 } }}>
				<DialogTitle>Token Form</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>
						<Typography>
							Tokens will be securely stored on AWS Secret Manager
						</Typography>
					</DialogContentText>
					<Box component="form">
						{tokenForm && tokenForm.map((field: TokenField, index: number) => (
							<TextField
								key={index}
								label={field.label}
								name={field.field}
								fullWidth
								margin="normal"
								value={formValues[field.field] || ''}
								onChange={(e) => handleFormChange(field.field, e.target.value)}
								sx={{ borderRadius: 3 }}
							/>
						))}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button sx={{ borderRadius: 3 }} onClick={handleTokenClose} color="error">
						Close
					</Button>
					<Button sx={{ borderRadius: 3 }} onClick={handleSaveTokens} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>

			{/* API Key Dialog */}
			<Dialog
				open={apiKeyDialogOpen}
				onClose={handleApiKeyClose}
				PaperProps={{ sx: { borderRadius: 5 } }}
				maxWidth={'md'}
			>
				<DialogTitle>API Key Management</DialogTitle>

				<DialogContent dividers>
					<Box>
						{/* If we have a raw key, show it and allow the user to copy */}
						{apiKey.raw && (
							<Box sx={{ my: 1 }}>
								<Typography sx={{ fontWeight: 'bold' }}>Your New API Key:</Typography>
								<Box display="flex" alignItems="center" mt={1}>
									<Typography sx={{ mr: 1, fontFamily: 'monospace' }}>
										{apiKey.raw}
									</Typography>
									<Tooltip title="Copy Key">
										<IconButton onClick={() => {
											if (apiKey.raw) navigator.clipboard.writeText(apiKey.raw)
										}} size="small"
										>
											<ContentCopyIcon fontSize="small" />
										</IconButton>
									</Tooltip>
								</Box>
								<Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
									Please copy this key and store it securely. You won’t be able to view it again.
								</Typography>
							</Box>
						)}

						{/* If keyData exists but no rawKey, show prefix + lastUsed info */}
						{!apiKey.raw && apiKey.is_active && (
							<Box sx={{ my: 2 }}>
								<Typography sx={{ mb: 1 }}>
									<strong>Key Prefix:</strong> {apiKey.prefix}
								</Typography>
								{apiKey.last_used_at && (
									<Typography sx={{ mb: 1 }}>
										<strong>Last Used:</strong> {new Date(apiKey.last_used_at).toLocaleString()}
									</Typography>
								)}
							</Box>
						)}

						{/* If no prefix at all => user can generate a new key */}
						{!apiKey.prefix && (
							<Typography variant="body2" sx={{ my: 2 }}>
								No active API key found. Generate one below.
							</Typography>
						)}
					</Box>
				</DialogContent>

				<DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
					{/* Show generate or rotate or revoke buttons depending on state */}

					{/* If prefix doesn't exist => show 'Generate' button */}
					{!apiKey.prefix && (
						<Button
							onClick={() => { sendMessage('generate_key') }}
							variant="contained"
							sx={{ borderRadius: 3 }}
						>
							Generate Key
						</Button>
					)}

					{/* If keyData exists => show rotate & revoke */}
					{apiKey.prefix && apiKey.is_active && (
						<Box>
							<Button
								onClick={() => { sendMessage('generate_key') }}
								variant="outlined"
								sx={{ borderRadius: 3 }}
							>
								Rotate Key
							</Button>
							<Button
								onClick={() => { sendMessage('revoke_key') }}
								variant="outlined"
								color="error"
								sx={{ borderRadius: 3, ml: 1 }}
							>
								Revoke Key
							</Button>
						</Box>
					)}

					<Button sx={{ borderRadius: 3 }} onClick={handleApiKeyClose} color="inherit">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SyncDialog;