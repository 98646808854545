import { DependencyStore, PresetManager } from "@bryntum/scheduler";
import { BryntumScheduler } from "@bryntum/scheduler-react";
// mui icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, {
  FunctionComponent, useEffect, useRef,
  useState
} from "react";
import { doAlert } from "../../utils/globalComps/PopupAlert/PopupAlert";
import CounterWeightDrag from "./lib/CounterWeightDragHelper";
import CustomDrag from "./lib/CustomDragHelper";
import HardBreakStore from "./lib/HardBreakStore";
import OrderStore from "./lib/OrderStore.js";
import RowStore from "./lib/RowStore.js";
import { createSchedulerConfig, DEFAULT_WORKING_TIME, schedulerFeatures, WorkingTimeConfig } from "./lib/SchedulerConfig";
import { customPresets } from "./lib/SchedulerTimeConfig";
import {
  buildDependencies, checkCounterWeightNeighbors, getOrdersWithStartDates, getResourcesFromOrders,
  mapHardBreaksToSaveData,
  mapOrdersToSaveData, rebuildSchedulerData
} from "./lib/Util";
import PopupAddHardBreak from './PopupAddHardBreak';
import PopupOrderDetail from './PopupOrderDetail';
import PopupReschedule from './PopupReschedule';
import ViewPresetDropdown from './ViewPresetDropdown';
import UpdateMoldCountModal from "../../utils/globalComps/RotoScheduler/UpdateMoldCountModal";
import {setMoldLoadCount} from "../../utils/globalComps/RotoScheduler/RotoSchedulerApi";
import LoadToArmModal from "../pages/armloading/LoadToArmModal";
import { Fab, Tooltip } from "@mui/material";
import WeekendIcon from '@mui/icons-material/Weekend'
;
interface BscProps {
  readOnly?: boolean;
  orders: any[];
  hardBreaks?: any[];
  unassignedStore?: any;
  armId: any;
  dragContainer?: any;
  saveCallback?: (data: any) => void;
  refresh?: boolean;
  forceRefresh?: () => void
  arms?: any[]
  canEdit: boolean | undefined
  loadToArmAction?: (data: any, cb: () => void) => void
	workingTime: WorkingTimeConfig
	setWorkingTime: (newWorkingTime: WorkingTimeConfig) => void
}

/**
 *
 * @param {boolean} [readOnly=true] Bool if scheduler is read only
 * @param {any[]} orders Array of orders that are placed on Scheduler
 * @param {OrderStore} [unassignedStore] Unassigned stoe needed by scheduler to add or remove items
 * @param {number} armId ID of arm
 * @param {HTMLElement} [dragContainer] Ref to the external drag container
 * @returns
 */
const BryntumSchedulerComponent: FunctionComponent<BscProps> = ({
  readOnly = true,
  orders,
  hardBreaks = [],
  unassignedStore,
  armId,
  dragContainer,
  saveCallback,
  refresh,
  forceRefresh,
  arms,
  canEdit,
  loadToArmAction, 
	workingTime, 
	setWorkingTime
}) => {
  //#region State
  // Scheduler is read only if the param is true, or there was no dragContainer / unassignedStore passed
  const isReadOnly = readOnly || !dragContainer || !unassignedStore;
  // Create ref for scheduler
  const schedulerRef: any = useRef<BryntumScheduler>(null);
  // State hook for incremental IDs for hard break lines. There is most certainly a better way of doing this
  const [hbIdIncrement, setHbIdIncrement] = useState(1);
  // Bool for when the dialog for adding a hard break is open
  const [addHardBreakOpen, setAddHardBreakOpen] = useState(false);
  // Bool for when the details about an order is open
  const [orderDetailOpen, setOrderDetailOpen] = useState(false);
  // Bool for when the rescheduling dialog should be open
  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  // Conext about the order that was dropped. Used in the dialog popup 
  const [rescheduleContext, setRescheduleContext] = useState(null);
  // Reference to the order that is being viewed
  const [orderDetail, setOrderDetail] = useState(null);
  // The ID of the time preset. Defaults to oneWeek. See lib/SchedulerTimeConfig.tsx
	const [activePreset, setActivePreset] = useState(readOnly ? 'dayPreset' : 'oneWeekPreset');
  // Array of dependencies that are tracked while an order is dragged. If the operation is invalid, we 
  // reinsert these in the dependency store  
  const [ghostDependencies, setGhostDependencies] = useState([])
  // mold count update id and quantity used in mold count update modal
  const [updateMoldCountId, setUpdateMoldCountId] = useState<number | undefined>(undefined)
  const [updateMoldCountQuantity, setUpdateMoldCountQuantity] = useState<number>(1)
  // load to arm action
  const [loadToArmModalId, setLoadToArmModalId] = useState<number | undefined>(undefined)
  const [loadToArmModalName, setLoadToArmModalName] = useState<string | undefined>(undefined)
  const [loadToArmModalCount, setLoadToArmModalCount] = useState<number | undefined>(undefined)
  const [loadToArmModalLoadingMore, setLoadToArmModalLoadingMore] = useState<boolean>(false)
  //#endregion

  //#region Stores
  // EventStore for the scheduler. Holds the scheduled orders
  const [scheduledStore] = useState(
    new OrderStore({ data: orders }, { armId }));
  // ResourceStore for the scheduler. We grab the resources (rows) based off the passed orders
  const [rowStore] = useState(
    new RowStore({ data: getResourcesFromOrders(orders, armId, true) })
    );
  // Time range store for scheduler. Holds data about hard breaks
  const [hardBreakStore] = useState(
    new HardBreakStore({ data: hardBreaks })
  );
  // DependencyStore for the scheduler. Hold the arrows that point to orders loaded after eachother 
  const [dependencyStore] = useState(
    new DependencyStore({ data: buildDependencies(orders) })
  );
  //#endregion

  // Function thats called when the order details dialog is closed
  const closeOrderDetailDialog = () => {
    setOrderDetailOpen(false);
    setOrderDetail(null);
  };

  // Function thats called when the hard break dialog is closed
  const closeHardBreakDialog = () => {
    setAddHardBreakOpen(false);
  };

  // Function thats called when a hard break line is added
  const addHardBreakLine = (value: any) => {
    const { startDate } = value;
    const newHardBreakObj = {
      id: hbIdIncrement,
      startDate,
      notes: "",
      name: "Hard Break",
      cls: "hard-break-scheduler",
      armId: armId,
    };
    setHbIdIncrement(hbIdIncrement + 1);
    schedulerRef.current.instance.timeRangeStore.add(newHardBreakObj);
  };

  // Function thats called when a hard break line is removed
  const removeHardBreakLine = (config: any) => {
    // This is by far the most hacky solution
    let { targetElement } = config;
    if (targetElement.tagName === "LABEL") {
      targetElement = targetElement.parentElement;
    }
    const data = targetElement.dataset.id;
    schedulerRef.current.instance.timeRangeStore.remove(data);
  };

  const mapSaveData = (modifications: any[], operation: string) => {
    const mappedData: any[] = [];

    for (const element of modifications)
      mappedData.push({...element.data, operation: operation})

    return mappedData;
  }

  const getSetEntryById = (set: any, id: string | number) => {
    let found: boolean = false;

    for (const setEntry of set)
      if (setEntry.id === id)
        found = true;

    return found;
  }

  // Callback when the save button is clicked. Note: These details are useful but we'll need to do
  // more work to realign the priority queue. See note below
  const saveChanges = () => {
    const schedulerInstance = schedulerRef.current.instance;
    const { eventStore, resourceStore, timeRangeStore} = schedulerInstance
    // Grab all modifications from each store. This is more for accessibility
    const modifications: any = {
      scheduler: {
        added: eventStore.added.items,
        removed: eventStore.removed.items,
        modified: eventStore.modified.items,
      },
      unscheduledOrders: {
        added: unassignedStore.added.items,
        removed: unassignedStore.removed.items,
        modified: unassignedStore.modified.items,
      },
      resources: {
        added: resourceStore.added.items,
        removed: resourceStore.removed.items,
        modified: resourceStore.modified.items,
      },
      hardBreaks: {
        added: timeRangeStore.added.items,
        removed: timeRangeStore.removed.items,
        modified: timeRangeStore.modified.items,
      },
    };
    // To-Do: We need to reconnect the priority queue so we can update the items with new "load-after" orders.
    // This list of modifications will help; but the process is going to be a little complicated.

    const counterWeightNeighbors: any = checkCounterWeightNeighbors(eventStore);
    // console.log('counterWeightNeighbors', counterWeightNeighbors)
    if (counterWeightNeighbors.valid) {
      // Push all for hard break modifications. We want to track what's been added, updated, or removed to sync with the DB. 
      const hardBreakModifications: any = [];
      hardBreakModifications.push(mapHardBreaksToSaveData(mapSaveData(modifications.hardBreaks.added, 'add')));
      hardBreakModifications.push(mapHardBreaksToSaveData(mapSaveData(modifications.hardBreaks.modified, 'update')));
      hardBreakModifications.push(mapHardBreaksToSaveData(mapSaveData(modifications.hardBreaks.removed, 'delete')));

      // Update orders 'load_after_order_id' and 'load_after_counter_weight_id' based on changes that have been made
      let orderArray: any[] = [];
      eventStore.allRecords.forEach((scheduledOrder: any, i: any) => {
        const previousOrder = eventStore.allRecords.find((rec: any) => rec.id === scheduledOrder.previousLoadOrder)
        orderArray.push({
          ...scheduledOrder,
          data: {
            ...scheduledOrder.data,
            scheduled_resource_id: scheduledOrder.resourceId,
            load_after_order_id: previousOrder && previousOrder.data.type !== 'counter_weight' ? previousOrder.data.id : null,
            load_after_counter_weight_id: previousOrder && previousOrder.data.type === 'counter_weight' ? previousOrder.data.id : null,
          }
        })
      })


      // Assign an operation to each order. All orders will be an update- while counterweight will either be added or updated.
      orderArray = orderArray.map((order: any) => {
        if (order.data.type === 'counter_weight') {
          const added: boolean = getSetEntryById(modifications.scheduler.added, order.data.id);
          return {
            ...order.data,
            operation: added ? 'add' : 'update',
          }
        } else {
          // assuming all orders are updates, could have stale data such as load_after ids
          return {
            ...order.data,
            operation: 'update'
          }
        }
      })

      
      // Find orders that were unscheduled or counter weights that need to be deleted
      for (const order of modifications.scheduler.removed) {
        orderArray.push({
          ...order.data,
          operation: order.data.type === 'counter_weight' ? 'delete' : 'update',
          load_after_order_id: null,
          load_after_counter_weight_id: null,
          scheduled_resource_id: null
        })
      }

      const counterWeightModifications: any = mapOrdersToSaveData(orderArray.filter((order: any) => order.type === 'counter_weight'));
      const orderModifications: any = mapOrdersToSaveData(orderArray.filter((order: any) => order.type !== 'counter_weight'));

      // TEMPORARILY CANCELING SAVE CALLBACK UNTIL WE CAN FIGURE OUT WHY STATE IS REFRESHING SO FREQUENTLY. The useEffect in RotoScheduler is causing problems 
      // After the save callback invokes that request
      if (saveCallback)
        saveCallback({
          hardBreaks: hardBreakModifications.flat(),
          counterWeights: counterWeightModifications,
          orders: orderModifications
        })

      eventStore.commit()
      unassignedStore.commit()
      resourceStore.commit()
      timeRangeStore.commit()
      doAlert(`Save successful on Arm: ${armId}`, 'success', true);
    } else {
      doAlert(`Cannot have a Counter Weight loaded after another Counter Weight. Occurrences: ${counterWeightNeighbors.occurrences}`, 'error', true);
    }
  };

  // Callback when the discard changes button is clicked. Reverts all relevant stores.
  const cancelChanges = () => {
    scheduledStore.resourceStore.revertChanges(); // reset the rows
    unassignedStore.revertChanges(); // reset the unassigned orders
    schedulerRef.current.instance.timeRangeStore.revertChanges(); // reset the hard breaks
    scheduledStore.revertChanges(); // reset the scheduled orders
  };

  // Callback when a time preset is changed. It helps to pass the CURRENT date as the begin / end
  // date to allow the scheduler to refresh and fit the view correctly.
  const handlePresetChange = (e: any) => {
    const presetId = e.target.value;
    schedulerRef.current.instance.zoomTo({
      preset: presetId,
      startDate: new Date(),
      endDate: new Date(),
    });

    setActivePreset(presetId);
  };

  // Function that is called when the reschedule popup is opened
  const openRescheduleDialog = (context: any) => {
    setRescheduleOpen(true)
    setRescheduleContext(context)
  }

  // Function that is called when the reschedule popup is closed
  const closeRescheduleDialog = () => {
    setRescheduleOpen(false)
    setRescheduleContext(null)
  }

  // Function that is executed when an order has been dropped BEFORE invoking the scheduler's eveentStore onUpdate. 
  // This is only invoked if the order is on the scheduler; If the order was dragged from the unassigned table, we handle
  // that logic in the CustomDragHelper.  
  const checkDroppedOrderValidity = (props: any) => {
    const { context } = props

    // If the order was dropped on the unscheduled order container
    if (context.externalDropTarget) {
      context.valid = true
    } 
    // Otherwise, the order was dropped on the scheduler 
    else {
      // Emulate the new data for the dropped event. Before this, the data for the dropped event is old because 
      // it hasn't triggered the update event
      const droppedEvent: any = { 
        ...context.eventRecord, 
        startDate: context.startDate, 
        endDate: context.endDate,
        data: {
          ...context.eventRecord.data,
          resourceId: context.newResource.id 
        }
      }

      // Check if we can auto schedule or if we need a popup 
      const response = scheduledStore.doesEventRequirePopup(
        droppedEvent,
        context.newResource.events
      )
      const [canAutoSchedule, needPopup] = response
      const intersectingOrder: any = response[2]

      // Is the drop location valid. If this is false, the event will auto shift back to its original location
      context.valid = canAutoSchedule
      if (!canAutoSchedule) {
        // If we can't auto schedule, we still might need to open the popup...
        if (needPopup) {
          openRescheduleDialog({...context, intersectingOrder})
        }
        // We're temporarily reverting the operation- so reinsert the dependencies 
        if (ghostDependencies.length > 0) {
          // This is a small quality of life improvement- but the event store hasn't refreshed yet so
          // the dependency lines will point to NULL (infinitly to the left). Wait 350ms and then reinsert
          setTimeout(() => {
            dependencyStore.add(ghostDependencies)
            setGhostDependencies([])
          }, 350);

        }
      } 
    }
  }

  // Function that is executed when an order [that is already on the scheduler] is dragged. We want to remove the dependendies 
  // linked to this order- but store them in case the drag operation is invalid so we can resinsert them. 
  const onOrderDragStart = (props: any) => {
    // Track the dependencies in case we need to reinsert them if the operation requires a popup.
    // We do remove them temporarily here.
    const depIds: any = []
    const deps: any = []
    props.eventRecords.forEach((ev: any) => {
      if(!depIds.includes(ev.id)) {
        depIds.push(ev.id)
        const toDeps = dependencyStore.findByField('to', ev.id)
        const fromDeps = dependencyStore.findByField('from', ev.id)
        // See return type for findByField. We need to map to 'data' property to get an array of records
        // https://bryntum.com/products/scheduler/docs/api/Scheduler/data/DependencyStore#function-findByField
        const flattened = toDeps.concat(fromDeps).flat().map((d: any) => d.data)
        // This is very redundant, but theres a bug in their code for findByField. Doesn't parse ids correctly. 
        // Ie. Will pull records with id 6, 16, 166 IF the eventRecord is id=6. Very annoyning -__-
        const filtered = flattened.filter((dep: any) => (dep.to === ev.id || dep.from === ev.id))
        deps.push(filtered)
      }
    })

    setGhostDependencies(deps.flat())
    dependencyStore.remove(deps.flat())
  }

  // Function that is executed when an order has been dropped AFTER checking the validity of the drop (see function checkDroppedOrderValidity)
  const onOrderDrop = (props: any) => {
    const { externalDropTarget, eventRecords, context } = props;

    //If this event was dropped on the custom drag container wrapper
    if (externalDropTarget) {
      // For multi-select, this list of events COULD include counter weights. We still want to remove them from the
      // schedule store and cleanup the resources- but we don't want to add them to the unassignesd store.
      const eventsToAddToUnassignedStore = eventRecords.filter(
        (ev: any) => ev.data.type !== "counter_weight"
      );
      // Unassign resource in case we drag the items back to the scheduler and we need a popup. If we don't unassign here,
      // the dropped event will be plotted on the scheduler to it's old resource when the popup appears. See lib/Util.ts/onUnassignedOrderDrop
      eventRecords.forEach((er: any) => {
        er.unassign(er.resourceId)
      })
      scheduledStore.remove(eventRecords);
      unassignedStore.add(eventsToAddToUnassignedStore);
    } else {
      const droppedEvent = context.eventRecord

      // Find the previous order in the resource that the dropped order landed in 
      const splitEvents = scheduledStore.splitEventsByResource(
        droppedEvent,
        context.newResource.events
      )
      const earlierEvents: any = splitEvents[0]
      const lastOrder = earlierEvents[earlierEvents.length -1]
      const oldPreviousLoadOrder = droppedEvent.data.previousLoadOrder

      // Update the priority queue to model the changes. All we need to do is update the previousLoadOrder, and then 
      // we will rebuild the scheduler data 
      const alteredPQ = scheduledStore.records.map((order: any) => {
        if (order.id === droppedEvent.data.id) {
          return { 
            ...order.data, previousLoadOrder: lastOrder ? lastOrder.id : null
          }
        }
        else if (order.previousLoadOrder === droppedEvent.data.id) {
          return { 
            ...order.data, previousLoadOrder: oldPreviousLoadOrder
          }
        }
        return order.data
      })
     
      // Push the droppedEvent to the end of the array so it will visually appear correctly in the scheduler
      const temp = alteredPQ.find((p: any) => p.id === droppedEvent.data.id)
      const allOrders = alteredPQ.filter((f: any) => f.id !== droppedEvent.data.id) 
      allOrders.push(temp)

      const stores = { 
        eventStore: scheduledStore, 
        resourceStore: rowStore, 
        dependencyStore: dependencyStore 
      }

      // This func will update the scheduler's stores 
      rebuildSchedulerData(allOrders, armId, stores, workingTime)
    }
  }

  useEffect(() => {
    // Instantiate the drag classes here. It's config ties the scheduler to the container
    // for drag and drop orders. (ONLY for dragging items onto the scheduler. Dragging items back to the
    // unqueued list has logic handled in the Scheduler component itself.)
    // console.log('use effect scheduler :', isReadOnly)
    if (!isReadOnly) {
      new CustomDrag({
        armId,
        unassignedStore,
        schedule: schedulerRef.current?.instance,
        outerElement: dragContainer.current,
        dropTargetSelector: `.scheduler-${armId} .b-timeline-subgrid`,
        openRescheduleDialog
      });

      new CounterWeightDrag({
        armId,
        schedule: schedulerRef.current?.instance,
        dropTargetSelector: `.scheduler-${armId} .b-timeline-subgrid`,
        openRescheduleDialog
      });
    }
    // Add custom time presets to PresetManager one time when page loads
    PresetManager.add(customPresets);
  }, [armId, dragContainer, isReadOnly, unassignedStore, refresh]);

  // mold count helpers
  const openUpdateMoldCountModal = (id: number, quantity: number) => {
    setUpdateMoldCountId(id)
    setUpdateMoldCountQuantity(quantity)
  }
  const closeUpdateMoldCountModal = () => {
    setUpdateMoldCountId(undefined)
    setUpdateMoldCountQuantity(1)
  }
  const doUpdateMoldCount = (data: any) => {
    setMoldLoadCount({...data, rotoScheduler: true}, () => {
      closeUpdateMoldCountModal()
      if (forceRefresh) forceRefresh()
    })
  }

  // load to arm helpers
  const openLoadToArm = (id: number, orderName: string, count: number) => {
    console.log('open load to arm :', id, orderName, count)
    setLoadToArmModalName(orderName)
    setLoadToArmModalCount(count)
    setLoadToArmModalId(id)
  }
  const closeLoadToArm = () => {
    setLoadToArmModalId(undefined)
    setLoadToArmModalName(undefined)
    setLoadToArmModalCount(undefined)
  }
  const doLoadToArm = (data: any) => {
    if (loadToArmAction)
      loadToArmAction(data, () => {
        closeLoadToArm()
      })
  }

	// Add interfaces for the props
	interface WeekendToggleFabProps {
		excludeWeekends: boolean;
		onChange: (checked: boolean) => void;
	}

	const WeekendToggleFab: React.FC<WeekendToggleFabProps> = ({ excludeWeekends, onChange }) => (
		<Box sx={{
			position: 'relative',
			top: '5px',
			left: '5px',
			zIndex: 999,
			height: 0, 
			width: 0
		}}>
			<Tooltip title={`${excludeWeekends ? 'Show' : 'Hide'} Weekends`}>
				<Fab
					size="small"
					color={excludeWeekends ? "primary" : "default"}
					onClick={() => onChange(!excludeWeekends)}
					sx={{
						boxShadow: 2,
						'&:hover': {
							opacity: 0.9
						}
					}}
				>
					<WeekendIcon sx={{}} />
				</Fab>
			</Tooltip>
		</Box>
	);

  return (
    <div id="schedulerContainer" className={`scheduler-${armId}`}>
			<WeekendToggleFab
				excludeWeekends={workingTime.excludeWeekends}
				onChange={(checked: boolean) => {
					const newConfig = {
						...workingTime,
						excludeWeekends: checked
					};
					setWorkingTime(newConfig);

					const scheduler = schedulerRef.current.instance;
					const { eventStore, resourceStore, dependencyStore } = scheduler;

					// Find the first event's original start date
					const firstEvent = eventStore.records.find((record: any) => record.previousLoadOrder === null);
					const originalStartDate = firstEvent ? firstEvent.startDate : scheduler.startDate;

					// Get a clean copy of the data with original durations
					const allOrders = eventStore.records.map((record: any) => {
						const cleanData = {
							...record.data,
							duration: record.originalDuration || record.duration,
							originalDuration: record.originalDuration || record.duration,
							weekendAdjusted: false,
							// Keep the first event's start time consistent
							...(record.previousLoadOrder === null && { startDate: originalStartDate })
						};
						return cleanData;
					});

					// Start fresh
					eventStore.beginBatch();
					dependencyStore.removeAll();
					eventStore.removeAll();

					// Update scheduler config
					scheduler.workingTime = checked ? {
						fromDay: Math.min(...newConfig.workingDays),
						toDay: Math.max(...newConfig.workingDays),
						fromHour: newConfig.workingStartHour,
						toHour: newConfig.workingEndHour
					} : undefined;

					// Recalculate and rebuild everything from scratch
					const ordersWithStartDates = getOrdersWithStartDates(allOrders, armId, newConfig, originalStartDate);

					// Add the recalculated events back
					eventStore.add(ordersWithStartDates);

					// Rebuild dependencies
					const newDeps = buildDependencies(ordersWithStartDates);
					dependencyStore.add(newDeps);

					eventStore.endBatch();

					// Ensure the scheduler's view is centered on the original start date
					scheduler.setTimeSpan(originalStartDate);
					scheduler.refresh();
				}}
			/>
      <Box flexDirection="column" className="flex-grow">
				
        {/* Both containers needs CSS properties in flex-grow class */}
        <div id="bryntumScheduler" className="flex-grow bryntumScheduler">
          <BryntumScheduler
            ref={schedulerRef}
            readOnly={readOnly}
            crudManager={{
              validateResponse: true,
              eventStore: scheduledStore,
              resourceStore: rowStore,
              timeRangeStore: hardBreakStore,
              dependencyStore: dependencyStore,
            }}
            viewPreset={activePreset}
            presets={customPresets}
            eventEditFeature={false}
            eventDragCreateFeature={false}
            scheduleMenuFeature={false}
            scheduleTooltipFeature={false}
            dependenciesFeature={schedulerFeatures.dependencies}
            timeRangesFeature={schedulerFeatures.timeRanges}
            eventTooltipFeature={schedulerFeatures.eventTooltip}
            eventDragFeature={{
              // Allow dragging orders outside of the Scheduler
              constrainDragToTimeline: false,
              // This CSS selector defines where a user may drop orders outside the scheduler element
              externalDropTargetSelector: "#unqueuedItemsContainer",
            }}
            eventMenuFeature={{
              items: {
                copyEvent: false,
                cutEvent: false,
                unassignEvent: false,
                deleteEvent: false,
                unassign: !readOnly && {
                  icon: 'b-fa b-fa-fw b-fa-trash',
                  text: "Unassign",
                  weight: 200,
                  onItem: (config: any) => {
                    // Unassign resource in case we drag the item back to the scheduler and we need a popup. If we don't unassign here,
                    // the dropped event will be plotted on the scheduler to it's old resource when the popup appears. See lib/Util.ts/onUnassignedOrderDrop
                    config.eventRecord.unassign(config.eventRecord.resourceId)
                    scheduledStore.remove(config.eventRecord);
                    if (config.eventRecord.data.type !== "counter_weight") {
                      unassignedStore.add(config.eventRecord);
                    }
                  },
                },
                moldCount: !readOnly && {
                  icon: 'b-fa b-fa-fw b-fa-pencil',
                  text: 'Update Mold Count',
                  weight: 300,
                  onItem: (config: any) => {
                    openUpdateMoldCountModal(config.eventRecord.roto_id, parseInt(config.eventRecord.mold_load_quantity))
                  },
                },
                loadToArm: loadToArmAction && readOnly && {
                  icon: 'b-fa b-fa-fw b-fa-arrow-circle-up',
                  text: 'Load to Arm',
                  weight: 350,
                  onItem: (config: any) => {

                    setLoadToArmModalLoadingMore(config.eventRecord.item_currently_molding)

                    openLoadToArm(config.eventRecord.item_currently_molding ? config.eventRecord.roto_id : config.eventRecord.arm_scheduled_id,
                      `${config.eventRecord.number} ${config.eventRecord.item}`,
                      config.eventRecord.mold_load_quantity)
                  }
                },
                orderDetails: {
                  icon: 'b-fa b-fa-fw b-fa-eye',
                  text: "See Order Details",
                  weight: 400, // Add the item to the bottom
                  onItem: (config: any) => {
                    setOrderDetailOpen(true);
                    setOrderDetail(config.eventRecord);
                  },
                },
              },
              processItems: (config: any) => {
                const { eventRecord, items } = config;
                if (!eventRecord.draggable) {
                  items.unassign = false;
                }

                if ((config.eventRecord.load_after_order_id !== null || !config.eventRecord.loadable)) {
                  items.loadToArm = false
                }

                if (config.eventRecord.data.type === "counter_weight") {
                  items.orderDetails = false;
                }
              },
            }}
            timeAxisHeaderMenuFeature={{
              items: {
                eventsFilter: false,
                zoomLevel: false,
                dateRange: false,
                currentTimeLine: false,
                removeHardBreak: {
                  text: "Remove Hard Break",
                  weight: 400,
                  onItem: removeHardBreakLine,
                },
              },
              processItems: (config: any) => {
                const { targetElement } = config;
                const timeRangeClasslist = targetElement?.classList;
                const timeRangeParentClasslist =
                  targetElement?.parentElement?.classList;
                if (
                  (!timeRangeClasslist.value.includes("timerange") &&
                    !timeRangeParentClasslist.value.includes("timerange")) ||
                  isReadOnly
                ) {
                  config.items.removeHardBreak = false;
                }
              },
            }}
            onBeforeEventDropFinalize={checkDroppedOrderValidity}
            onEventDragStart={onOrderDragStart}
            onEventDrop={onOrderDrop}
						{...createSchedulerConfig(workingTime)}
          />
        </div>
       
				<Box mt={!readOnly ? 1 : 0} display="flex" justifyContent="space-between">
					{!readOnly && <Box sx={{display: 'flex', alignItems: 'center', my: 'auto'}}>
            <IconButton
              size={"small"}
              sx={{ display: "flex", mt: 1 }}
              color="primary"
              onClick={() => schedulerRef.current.instance.shiftPrevious()}
            >
              <ChevronLeftIcon />
            </IconButton>
            <ViewPresetDropdown
              selectedPreset={activePreset}
              presets={customPresets}
              handleChange={handlePresetChange}
            />
            <IconButton
              size={"small"}
              sx={{ display: "flex", mt: 1 }}
              color="primary"
              onClick={() => schedulerRef.current.instance.shiftNext()}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>}
          {/* If read only, don't include toolbar to edit rows */}
          {!readOnly && (
            <Box display={"flex"}>
              <Box
                className={"counter-weight-button scheduler-counter-weight"}
								sx={{ marginRight: 1, borderRadius: 3 }}
              >
                <span style={{ display: "flex" }}>COUNTER WEIGHT</span>
              </Box>
              <Button
                variant="outlined"
                size={"small"}
                sx={{ display: "flex", marginRight: 1, borderRadius: 3 }}
                color="info"
                onClick={() => setAddHardBreakOpen(true)}
              >
                Add Hard Break
              </Button>
              <Button
                variant="outlined"
                size={"small"}
								sx={{ display: "flex", marginRight: 1, borderRadius: 3 }}
                onClick={saveChanges}
              >
                Save Changes
              </Button>
              <Button
                variant="outlined"
                size={"small"}
								sx={{ display: "flex", borderRadius: 3 }}
                color="error"
                onClick={cancelChanges}
              >
                Discard Changes
              </Button>
            </Box>
          )}
        </Box>
        <PopupOrderDetail
          closeOrderDetail={closeOrderDetailDialog}
          event={orderDetail}
          orderDetailOpen={orderDetailOpen}
        />
        <PopupAddHardBreak
          onClose={closeHardBreakDialog}
          open={addHardBreakOpen}
          onSave={addHardBreakLine}
        />
        <PopupReschedule
          open={rescheduleOpen}
          onClose={closeRescheduleDialog}
          rescheduleContext={rescheduleContext}
          armId={armId}
					workingTime={workingTime}
        />
        <UpdateMoldCountModal
          orderId={updateMoldCountId}
          quantity={updateMoldCountQuantity}
          onSubmit={doUpdateMoldCount}
          onClose={closeUpdateMoldCountModal}
          rotoScheduled
        />
        <LoadToArmModal
          id={loadToArmModalId}
          orderName={loadToArmModalName}
          arms={arms}
          count={loadToArmModalCount}
          givenArm={armId}
          canChoose={canEdit}
          onClose={closeLoadToArm}
          onSubmit={doLoadToArm}
          rotoScheduled
          loadingMore={loadToArmModalLoadingMore}
        />
      </Box>
    </div>
  );
};

export default BryntumSchedulerComponent;
