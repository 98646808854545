import { DateHelper, DomHelper } from "@bryntum/scheduler";
import {
  // ONLY_WORKING_HOURS,
  // WORKING_START_DAY,
  // WORKING_END_DAY,
  // START_DATE,
  WORKING_START_HOUR,
  WORKING_END_HOUR,
  DEFAULT_WORKING_TIME,
  WorkingTimeConfig,
} from "./SchedulerConfig";
import Order from './Order'

// counter weight neighbor warning
const checkCounterWeightNeighbors = (eventStore: any) => {

  const records = eventStore.allRecords
  console.log('eventStore.allRecords', eventStore.allRecords)

  const getCounterWeightErrors = (eventRecord: any, children: any) => {
    const recordIsCounterWeight = eventRecord.data.type === "counter_weight"
    const childOrders = records.filter(
      (ord: any) => ord.data.previousLoadOrder === eventRecord.data.id
    );
    childOrders.forEach((childOrder: any) => {
      const childIsCounterWeight = childOrder.data.type === "counter_weight"
      if (recordIsCounterWeight && childIsCounterWeight) {
        children.push({ parent: eventRecord, child: childOrder})
      }
      const otherErrors = getCounterWeightErrors(childOrder, children);
      children.concat(otherErrors);
    });

    return children;
  };

  const allCounterWeightErrors: any = []
  const rootOrders = records.filter((order: any) => order.previousLoadOrder === null)
  rootOrders.forEach((rootOrder: any) => {
    allCounterWeightErrors.push(getCounterWeightErrors(rootOrder, []))
  });

  const errorFlattened = allCounterWeightErrors.flat()
  console.log('errorFlattened', errorFlattened)
  return { valid: errorFlattened.length === 0, occurrences: errorFlattened.length }
};

// The orders that are CURRENTLY MOLDING have a different structure returned than the
// the enhanced-loadqueue
const normalizeOrder = (order: any) => ({
  ...order,
  item_takt: order.takt,
  work_order_number: order.order,
});

// Helper function that adds the Scheduler's required fields to the order object. We want
// to avoid storing as much unecessary data as possible.
const mapToOrderModel = (orders: any) => {
  return orders.map((order: any) => ({
    ...order,
      duration: DateHelper.asMilliseconds(
		order.duration,
		"minute"
	  ),
      resourceId: order.scheduled_resource_id,
      name: `${order.item} | ${order.work_order_number} | ${order.balance} Parts | ${order.mold_load_quantity} Mold(s)`,
      draggable: !order.item_currently_molding,
      previousLoadOrder: order.load_after_order_id || order.load_after_counter_weight_id || null,
      resizable: false,
      cls: `scheduler-bar ${
        order.item_currently_molding ? "scheduler-bar-currently-molding" : ""
      }`,
  }));
};

// Helper function the maps counter_weights to the model needed by the scheduler
const mapToCounterWeightsModel = (counterWeights: any) => {
  return counterWeights.map((counterWeight: any) => ({
    ...counterWeight,
    duration: DateHelper.asMilliseconds(
      counterWeight.duration,
      'minute',
    ),
    resourceId: counterWeight.scheduled_resource_id,
    name: "Counter Weight",
    previousLoadOrder: counterWeight.load_after_order_id || counterWeight.load_after_counter_weight_id || null,
    draggable: true,
    resizable: true,
    type: "counter_weight",
    cls: "scheduler-bar scheduler-bar-counter-weight",
  }));
};

// Helper function to map a hardbreak to the model needed by the scheduler
const mapToHardBreaksModel = (hardBreaks: any) => {
  return hardBreaks.map((hardBreak: any) => ({
    ...hardBreak,
    startDate: new Date(hardBreak.start),
    name: "Hard Break",
    cls: "hard-break-scheduler",
  }));
};

// maps scheduler hardbreak model back to db model
const mapHardBreaksToSaveData = (hardBreaks: any) => {
  return hardBreaks.map((hardBreak: any) => ({
    id: hardBreak.operation === 'add' ? undefined : hardBreak.id,
    arm_id: hardBreak.armId,
    start: hardBreak.startDate,
    notes: hardBreak.notes,
    scheduled_resource_id: hardBreak.scheduled_resource_id,
    operation: hardBreak.operation
  }));
}

// maps scheduler order/counter weight model back to db model
const mapOrdersToSaveData = (orders: any) => {
  return orders.map((order: any) => ({
    id: order.id,
    duration: order.duration,
	mold_load_quantity: order.mold_load_quantity,
    scheduled_resource_id: order.scheduled_resource_id,
    work_order_number: order.work_order_number,
    load_after_order_id: order.load_after_order_id,
    load_after_counter_weight_id: order.load_after_counter_weight_id,
    operation: order.operation
  }))
}

// Utility: Convert a duration in ms into an "end date" that omits weekends.
const calculateWorkingEndDate = (
	startDate: Date,
	durationMs: number
): { endDate: Date; adjustedDuration: number } => {
	// We’ll take the start date and add "working days" one by one.
	// This is easy to understand but not the most optimal for large durations.
	// Optimize if needed.

	const msPerDay = 24 * 60 * 60 * 1000;
	let daysToAdd = Math.floor(durationMs / msPerDay);
	let remainderMs = durationMs % msPerDay;

	let resultDate = new Date(startDate);

	// Move forward day-by-day, skipping weekends
	while (daysToAdd > 0) {
		resultDate.setDate(resultDate.getDate() + 1);
		// If not Saturday(6) or Sunday(0), we count it as a working day
		if (resultDate.getDay() !== 6 && resultDate.getDay() !== 0) {
			daysToAdd--;
		}
	}

	// After the full working days, add the leftover remainder (partial day).
	// If that partial day spills into weekend, we bump to Monday.
	let tentativeEnd = new Date(resultDate.getTime() + remainderMs);

	// If we land on Sat or Sun, move forward until we hit a weekday
	while (tentativeEnd.getDay() === 6 || tentativeEnd.getDay() === 0) {
		tentativeEnd.setDate(tentativeEnd.getDate() + 1);
	}

	const adjustedEndDate = tentativeEnd;
	const adjustedDuration = adjustedEndDate.getTime() - startDate.getTime();

	return { endDate: adjustedEndDate, adjustedDuration };
}

const getOrdersWithStartDates = (
	allOrders: any[],
	armId: number,
	workingTime: WorkingTimeConfig,
	currentDate: Date = new Date()
) => {
	// 1) Reset duration to originalDuration for a clean rebuild
	allOrders.forEach(order => {
		if (!order.originalDuration) {
			order.originalDuration = order.duration;
		}
		order.duration = order.originalDuration;
		order.weekendAdjusted = false;
		order.startDate = null;
		order.endDate = null;
	});

	// 2) Assign rows (resource IDs) in a depth-first manner
	const assignResources = (rowNumber: number, order: any): number => {
		// Assign resource id
		order.resourceId = `${armId}-r${rowNumber}`;
		order.scheduled_resource_id = `${armId}-r${rowNumber}`;
		order.row = rowNumber;

		// Grab all children
		const childOrders = allOrders.filter(
			(child: any) => child.previousLoadOrder === order.id
		);

		// For each child, if it's the first child, reuse the same row,
		// otherwise go to the next row
		childOrders.forEach((childOrder: any, i: number) => {
			if (i === 0) {
				rowNumber = assignResources(rowNumber, childOrder);
			} else {
				rowNumber = assignResources(rowNumber + 1, childOrder);
			}
		});
		return rowNumber;
	};

	// 3) A recursive function to schedule a single "branch"
	const scheduleBranch = (order: any, branchStart: Date): Date => {
		// Start at the provided date
		order.startDate = branchStart;

		// Naive end = start + order.duration (in ms)
		const naiveEnd = new Date(order.startDate.getTime() + order.duration);
		let finalEnd: Date = naiveEnd;

		// If we exclude weekends, recalc end date by skipping Sat/Sun
		if (workingTime.excludeWeekends) {
			const { endDate, adjustedDuration } = calculateWorkingEndDate(
				order.startDate,
				order.duration
			);
			finalEnd = endDate;
			if (adjustedDuration !== order.duration) {
				order.weekendAdjusted = true;
			}
			order.duration = adjustedDuration;
		}

		// Assign final end date
		order.endDate = finalEnd;

		// Now schedule children so they start exactly when we ended
		const childOrders = allOrders.filter(
			(child: any) => child.previousLoadOrder === order.id
		);
		childOrders.forEach((childOrder: any) => {
			scheduleBranch(childOrder, order.endDate);
		});

		return order.endDate;
	};

	// 4) Assign resources (row/arm) from each root
	let rowIncrement = 1;
	const rootOrders = allOrders.filter(o => o.previousLoadOrder === null);
	rootOrders.forEach(root => {
		rowIncrement = assignResources(rowIncrement, root);
		rowIncrement++;
	});

	// 5) For each root, recursively schedule its entire branch
	rootOrders.forEach(root => {
		scheduleBranch(root, currentDate);
	});

	return allOrders;
};


// Helper function to grab the resources off of list of all scheduled orders. Used to build the
// ResourceStore on the scheduler.
const getResourcesFromOrders = (events: any[], armId: number, pushExtraRow: boolean = false) => {
  // Get unique resources from events
  let rows = events.reduce((accumulator: any, order: any) => {
    const ids = accumulator.map((row: any) => row.scheduled_resource_id)
    if (!ids.includes(order.scheduled_resource_id)) {
      accumulator.push({scheduled_resource_id: order.scheduled_resource_id, row: order.row});
    }
    return accumulator;
  }, []);

  // Sort the resources so they show up in the scheduler correctly 
  rows = rows.sort((a: any, b: any) => a.row - b.row)

  // Push a default resource because we always want one more than the initial length
  // Note: it might be a better idea to reference the last row in the sorted list rather than using 
  // the length of the rows array. A ID collission in the resource store could break the scheduler 
  if (pushExtraRow) {
    rows.push({scheduled_resource_id: `${armId}-r${rows.length + 1}`, row: rows.length + 1});
  }

  // Map to objects with the resource as the id. Needed for resource store
  return rows.map((res: any) => ({ id: res.scheduled_resource_id }));
};

const buildDependencies = (orders: any[]) => {
  return orders.filter((ord: any) => ord.previousLoadOrder !== null).map((order: any) => 
    ({ id : order.id, from : order.previousLoadOrder, to : order.id, fromSide: 'bottom' })
   )
 }

const rescheduleOrders = (eventRecord: any, intersectingOrder: any, armId: any, insertBetween: boolean, workingTime: WorkingTimeConfig) => {

	const eventStore = eventRecord.eventStore 
	const resourceStore = eventStore.resourceStore
	const dependencyStore = eventStore.dependencyStore

	// eventStore.beginBatch()
	// Track the dropped orders old parent order.
	const oldPreviousLoadOrder = eventRecord.data.previousLoadOrder

	// Construct the changes in the PQ [change the previouys load orders if necessary] 
	const alteredPQ = eventStore.records.map((order: any) => {
	// Order that was dropped on the scheduler
	if (order.id === eventRecord.id) {
		return { 
		...order.data, previousLoadOrder: intersectingOrder.id, hasBeenProcessed: true 
		}
	// If we want to shift future orders behind the dropped order.   
	} else if (insertBetween && order.previousLoadOrder === intersectingOrder.id) {
		return { 
		...order.data, previousLoadOrder: eventRecord.id, hasBeenProcessed: true 
		}
	// Shift the old orders that point to the order we just dropped. Move them up one level   
	} else if (order.previousLoadOrder === eventRecord.id) {
		return { 
		...order.data, previousLoadOrder: oldPreviousLoadOrder, hasBeenProcessed: true 
		}
	} 
	return order.data
	})

	// Push the dropped event to the end so it will populate the page correctly.
	const droppedEvent = alteredPQ.find((p: any) => p.id === eventRecord.id)
	const allOrders = alteredPQ.filter((f: any) => f.id !== eventRecord.id) 
	allOrders.push(droppedEvent)

	rebuildSchedulerData(allOrders, armId, { eventStore, resourceStore, dependencyStore }, workingTime)

}

const rebuildSchedulerData = (allOrders: any[], armId: number, stores: any, workingTime: WorkingTimeConfig) => {
  const { eventStore, resourceStore, dependencyStore } = stores

  const ordersWithStartDates = getOrdersWithStartDates(allOrders, armId, workingTime, eventStore.currentDate)

  // Grab the resources from the new priority queue. Then add or remove the necessary rows BEFORE updating the events.
  // NOTE: This list will ALWAYS contain an empty resource at the end. The last resource technically has no orders in it 
  const resources = getResourcesFromOrders(ordersWithStartDates, armId, true)

  const resStoreRecords = resourceStore.allRecords
  const resDiff = (resStoreRecords.length) - resources.length 
  // If there are resources we should delete 
  if (resDiff > 0) {
    const resourcesToDelete = resStoreRecords.slice(-Math.abs(resDiff))
    resourceStore.remove(resourcesToDelete.map((i:any) => i.id))
  } 
  // If there are resources to add
  else if (resDiff < 0) {
    const resourcesToAdd = resources.slice(resDiff)
    resourceStore.add(resourcesToAdd)
  }

  eventStore.beginBatch()

  // Update the events in the event store with their new values. This will trigger the onUpdate function in the OrderStore class,
  // but we override the records (hasBeenProcessed = true) that were updated here (see alteredPQ above)
  eventStore.records.forEach((recInStore: any) => {
    const correspondingOrder = ordersWithStartDates.find((o:any) => o.id === recInStore.id)
    recInStore.previousLoadOrder = correspondingOrder.previousLoadOrder
    recInStore.setStartDate(correspondingOrder.startDate);
    eventStore.assignEventToResource(recInStore, correspondingOrder.resourceId)
  })

  eventStore.endBatch()

  // Finally, reconstruct the deps so arrows will point correctly.
  dependencyStore.removeAll()
  const newDeps = buildDependencies(ordersWithStartDates)
  dependencyStore.add(newDeps)

}

const onUnassignedOrderDrop = (dragClass: any, context: any, isCounterweight: boolean = false ) => {
  const me = dragClass,
    order = context.task,
    target = context.target,
    resource = context.resource;

  // If drop was done in a valid location
  if (context.valid && target) {
    // Grab the start date and calculate the end date where the order was DROPPED (not where it should go)
    const droppedStartDate = me.schedule.getDateFromCoordinate(
      DomHelper.getTranslateX(context.element),
      "round",
      false
    );
    let droppedEndDate = DateHelper.add(
      droppedStartDate,
      order.duration,
      "ms"
    );

    // Emulate the changes in a temp order without applying changes to the order in the 'unassigned' store
    const mockedOrder = {
      ...order,
      startDate: droppedStartDate,
      endDate: droppedEndDate,
      data: {
        ...order.data,
        resourceId: resource.id,
      },
    };

    // Check if we need can autoschedule or if we need a popup
    const [canAutoSchedule, needPopup, intersectingOrder, earlierEvents] =
      me.schedule.eventStore.doesEventRequirePopup(
        mockedOrder,
        resource.events
      );

    if (canAutoSchedule) {
      // Remove order from unassigned store & assign resource to order. NOTE: removing from unassigned store has to be first (before
      // assigning to a resource or setting a new startDate) or the unassignedStore will throw an error.
      if (!isCounterweight) me.unassignedStore.remove(order);
      order.assign(resource);

      // If this is dropped on the last row, we need to add another resource  
      const isOnLastRow = me.schedule.eventStore.isEventOnLastRow(order)
      if (isOnLastRow) {
        const [armId, index] = resource.id.split("r")
        me.schedule.resourceStore.add({ id: `${armId}r${+index + 1}` })
      }

      // Find the last order in the resource BEFORE the dropped startDate. If there is none, it means the order should be pushed to
      // the front of the resource. If there is a previous order, set the dropped order's startDate to the previous order's endDate.
      const lastOrder = earlierEvents[earlierEvents.length - 1];
      // Update order's previousLoadOrder
      order.previousLoadOrder = lastOrder ? lastOrder.id : null;
      const newStartDate = lastOrder
        ? lastOrder.endDate
        : me.schedule.eventStore.currentDate;
      order.setStartDate(newStartDate, true);
      // Add event to eventStore
      me.schedule.eventStore.add(order);
    } else {
      // Cancel the drop operation. This will prevent the item from being removed from the 'unassigned' store
      context.valid = false;
      // If the operation couldn't be auto scheduled BUT we do need a popup
      if (needPopup) {
        // It gets a little funky here... To get the scheduler to build correctly, we technically need to 'add' this
        // order to the scheduler [this will sync the dropped order to the scheduler's eventStore, resourceStore, etc.].
        // However, we don't want it to be visible (because the user might cancel the operation) so we don't assign it
        // a resource (this basically makes it invisible). We pass a callback to the front-end that is executed after the popup
        // is closed- returing a boolean if the operation was executed or canceled... see notes below in outcomeCallback
        me.schedule.eventStore.add(order);

        // Helper function to maintain the integrity of both stores after a user chooses an option in the popup
        const outcomeCallback = (success: boolean) => {
          if (success) {
            // An operation was executed and the order should remain added to the scheduler. It is now a safe time to
            // remove the order from the unassigned store IF this isn't a counterweight.
            if (!isCounterweight) me.unassignedStore.remove(order);
          } else if (!success) {
            // The operation was canceled and the 'invisible' order should be removed from the eventStore
            me.schedule.eventStore.remove(order);
          }
        };

        const rescheduleContext = {
          eventRecord: order,
          intersectingOrder,
          callback: outcomeCallback,
        };
        // Open popup and pass context about the dropped event so the user can control how it is scheduled
        me.openRescheduleDialog(rescheduleContext);
      }
    }
  }

  me.schedule.element.classList.remove("b-dragging-event");
  // If this is a counterweight, we need to set the context's task [aka. order] back to undefined so the user can 
  // add another. The counterweight is technically the same dom element so the drag helper will persist the task  
  if (isCounterweight) context.task = undefined;
}

// NOTE: This is deprecated as of now. The goal was that if we used the Schedulers working days config,
// we'd have to extend the visual duration of orders to extend past 'out of work' hours. I'm keeping
// it here temporarily as the logic wasn't incorrect, but we're just not using it anymore.

// Helper function that adjusts the duration of the order based off the cofigurations for
// WORKING_START_HOUR and WORKING_END_HOUR
// To-Do: Add handling for weekends using the WORKING_START_DAY and WORKING_END_DAY values
const getEventAdjustedDuration = (event: any) => {
  const startDate = event.startDate;

  // Get the ending work hour in ms (default to 17 or 5:00 pm)
  const workingEndHourInMs = DateHelper.asMilliseconds(
    WORKING_END_HOUR,
    "hour"
  );
  // Get the start time in MS (NOT the start date)
  const startTimeInMs = DateHelper.getTimeOfDay(startDate);
  // Find the working-hour duration that the event can be scheduled in
  // the first day (amount of MS from startTime to pre-configured var
  // for the end of the work day)
  const initialDelta = workingEndHourInMs - startTimeInMs;
  // Find the remaining duration that needs to be adjusted for this event (MS)
  const durationToAllocateMs = event.scheduled_duration - initialDelta;
  // Convert previous value to hours
  const remainingHoursToAllocate = DateHelper.as("hour", durationToAllocateMs);
  // Get one full day in MS
  const oneDayInMs = DateHelper.asMilliseconds(1, "day");
  // Find the amount of working hours set by schedule config (default 9 hours) in hours
  const workingHours = WORKING_END_HOUR - WORKING_START_HOUR;
  // Find the duration of non-working hours (5:00PM - 8:00AM, 15 hrs) in MS
  const nonWorkingHoursMs =
    oneDayInMs - DateHelper.asMilliseconds(workingHours, "hour");
  // Find how many days we need to fill
  const numberOfDaysToFill = Math.ceil(remainingHoursToAllocate / workingHours);
  // Calculate the total amount of MS that need to be added to the initial duration
  const totalDaysFilledInMs = numberOfDaysToFill * nonWorkingHoursMs;

  return event.duration + totalDaysFilledInMs;
};

export {
  getEventAdjustedDuration,
  mapToOrderModel,
  normalizeOrder,
  mapToCounterWeightsModel,
  getOrdersWithStartDates,
  getResourcesFromOrders,
  mapToHardBreaksModel,
  mapHardBreaksToSaveData,
  mapOrdersToSaveData,
  checkCounterWeightNeighbors,
  buildDependencies,
  rescheduleOrders,
  rebuildSchedulerData,
  onUnassignedOrderDrop
}
